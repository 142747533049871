const data = [
    {
        title: "Garden",
        route: "intro"
    },
    {
        title: "Work",
        route: "portfolio"
    },
    {
        title: "Rabbit Hole",
        route: "contact"
    }
]

export default data;